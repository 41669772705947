import request from '@/utils/request'



// 医师*药师管理 - 医生/药师列表  /admin/doctors(新)
export function getDoctorsListNew(query) {
	return request({
		url: '/admin/manage/doctors/index',
		method: 'get',
		params: query
	})
}

//医师/药师管理 - 医生/药师详情 /admin/doctors/:id
export function getDoctorsDetailNew(query) {
	return request({
		url: '/admin/doc/info/' + query.id,
		method: 'get',
		params: query
	})
}

//医师/药师管理 - 医生/药师详情 /admin/doctors/:id(新)
export function getDoctorsDetails(query) {
	return request({
		url: '/admin/doc/detail/' + query.id,
		method: 'get',
		params: query
	})
}

//修改基础信息
export function reviseInformation(query) {
	return request({
		url: '/admin/doc/updateBasicInfo/' + query.id,
		method: 'post',
		data: query
	})
}

export function updateBasicInfo(query) {
	return request({
		url: '/admin/doc/updateDoctorBasic/' + query.id,
		method: 'post',
		data: query
	})
}


//修改接诊设置
export function admissionsSettings(query) {
	return request({
		url: '/admin/doc/updateSetting/' + query.id,
		method: 'post',
		data: query
	})
}

//新增工作经历
export function updateWork(query) {
	return request({
		url: '/admin/doc/updateWork/' + query.id,
		method: 'post',
		data: query
	})
}

//药师修改个人简介
export function updateIntroduction(query) {
	return request({
		url: '/admin/doc/uploadBrief/' + query.id,
		method: 'post',
		data: query
	})
}

//修改处罚
export function updatePunishment(query) {
	return request({
		url: '/admin/doc/updatePunish/' + query.id,
		method: 'post',
		data: query
	})
}


//审核医师
export function saveReviewed(query) {
	return request({
		url: '/admin/doc/examine/' + query.id,
		method: 'post',
		data: query
	})
}

//医师解约
export function physicianTermination(query) {
	return request({
		url: '/admin/doc/rescind/' + query.id,
		method: 'post',
		data: query
	})
}

//医师/药师监管列表
export function physicianSupervisionList(query) {
	return request({
		url: '/admin/supervise/doctors',
		method: 'get',
		params: query
	})
}

// 上传监管(医师,药师)
export function uploadSupervision(query) {
	return request({
		url: `/admin/uploadDocSupervise/${query.id}`,
		method: 'post'
	})
}

export function updateSupervision(data) {
	return request({
		url: `/admin/updateDoctorSuperviseStatus`,
		method: 'put',
		data
	})
}


//上传监管(机构)
export function institutionUpload(id) {
	return request({
		url: `/admin/signOrgan/supervision/${id}`,
		method: 'put'
	})
}

//机构修改启用状态
export function institutionalStatus(query) {
	return request({
		url: `admin/signOrgan/status/${query.id}`,
		method: 'post',
		data: query
	})
}

//审核(机构)
export function institutionReview(query) {
	return request({
		url: `admin/signOrgan/examine/${query.id}`,
		method: 'put',
		data: query
	})
}

// 医生接诊设置
export function postUpdateDiagnosisSetting(data, id) {
  return request({
    url: `admin/doc/updateDiagnosisSetting/${id}`,
    method: 'post',
    data
  })
}

// 获取医生接诊设置
export function getDiagnosisSetting(id, params) {
  return request({
    url: `admin/doc/getDiagnosisSetting/${id}`,
    method: 'GET',
    params
  })
}

//签约医师
export function signDoctor(id,data) {
	return request({
	  url: `admin/proxy/signDoctor/${id}`,
	  method: 'post',
	  data
	})
  }


  export function setDocRate(id,data) {
	return request({
	  url: `admin/proxy/setDocRate/${id}`,
	  method: 'post',
	  data
	})
  }
  
export function getAssistants(id,params) {
	return request({
		url: `admin/getAssistants/${id}`,
		method: 'get',
		params
	})
}

export function bindAssistant(id,ids) {
	return request({
		url: `admin/bindAssistant/${id}`,
		method: 'post',
		data:{ids}
	})
}
//机构选择
export function postAgentDoctor(query) {
	return request({
		url: '/admin/doctors/organization/bind/'+query.id,
		method: 'post',
		data:query
	})
}


  